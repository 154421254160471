.mainpg__container {
    height: 100vh;
    display:flex;
    scroll-behavior: smooth; 


}

.mainpg__vdo video {

    height: 79vh; 
    width: auto; 
    max-width: 100%; 
    animation: playVideo 5s infinite;
    margin-top: 9vw;
    margin-left: 26vw;

  
}

@keyframes playVideo {
    0% {
        transform: scale(1.001);
    }
    100% {
        transform: scale(1.001);
    }
}
.mainpg__text{
    position: absolute;
    top: 15vw;
    margin-left: -7vw;
    margin-top: -5vw;

}
.mainpg__text h1{
  font-size: 4.5rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  line-height: 5rem;
  width: 80%;
  
}
.mainpg__text h2{
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 2.1rem;
    margin-top: 1.5rem;
    color:rgb(90, 0, 158);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn11 {
    position: absolute;
    width: 250px;
    height: 60px;
    border: 2px solid #580d9f;
    border-radius: 50px;
    background: #fff;
    z-index: 1;
    margin-top: 50px;
    transition: all cubic-bezier(0.19, 1, 0.22, 1)2s;
   
}
.btn11:hover{
    width:300px;
}
.circle{
    position: absolute;
    width: 60px;
    height:60px;
    background:#851fea;
    border-radius:50px;
    left: 0;
    top: -2px;
    z-index: 2;
    transition:  .5s cubic-bezier(1, 0, .8 ,1 );
}

.btn11:hover .circle{
    width: 300px;
}

.arrow{
    position: absolute;
    width:20px;
    height: 20px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(45deg);
    top: 40px;
    left: 18px;
    transform: 5s; 
    margin-top: -20px;
}

.btn11:hover .arrow{
    left: 260px;
}

.arrow:before{
   content: '';
   position: absolute;
   width: 33px;
   height: 3px;
   background: #fff;
   transform: rotate(-45deg);
   left: -9px;
   top: 9px;
   opacity: 0;
   transition: .5s;


}

.btn11:hover .arrow:before{
   opacity: 1;

}

.btn12{
    width: max-content;
    display: inline-block;
    font-weight: bolder;
    color: var(--color-bg-variant);
    padding: 0.75rem 1.2rem;
    border-radius: 1.8rem;
    cursor: pointer;
    border: 4px solid var(--color-bg-variant);
    transition: var(--transition);
    margin-top: 8rem;
    font-size: 1.2rem;
  
  }
  
.btn12:hover {
    background: var(--color-bg-variant);
    color: var(--color-bg);
  }

.text{
    position: absolute;
    top: 0;
    left: 69px;
    font-size: 20px;
    line-height: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 600;
    z-index: 3;
    transition: .5s;
    margin-top: 22px;
    color:#580d9f;

}
.text:hover{
    color:#fff;
    font-size: 25px;
    margin-left: -28px;

    

}
/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (min-width: 601px) and (max-width: 750px) {
    
    .mainpg__container {
        height: 100vh;
        display:flex;
        scroll-behavior: smooth;
        flex-direction: column; 
    
    
    }

    .mainpg__vdo video {
    
        align-items: center;
        height: 59vh; 
        width: auto; 
        margin-left: 3vw;
        margin-top: 22vw;
      
    }

    .mainpg__text h1{
        
        text-align: center;
        font-size: 2.5rem;
        margin-left: 15vw;
        margin-top: 2vw;
        margin-bottom: 13vw;
        width: 100%;
        line-height: 3.1rem;
      
    }
    
    .mainpg__text h2{
        position: absolute;
        text-align: center;
        font-size: 1.rem;
        margin-top: 45vh;
        width: 90%;
        margin-left: 18vw;
        line-height: 1.1rem;
    }
    
    .btn11 {
       position: absolute;
       margin-left: 35vw;
       top: 77.3vh;
    }
    
    
}


/*-----------SOMFIN MEDIA QUERIES (Small devices)---------*/

@media screen and (max-width: 600px) {
    .mainpg__container {
        height: 100vh;
        display:flex;
        scroll-behavior: smooth;
        flex-direction: column; 
    
    
    }

    .mainpg__vdo video {
        height: 79vh; 
        width: auto; 
        margin-left: 3vw;
        margin-top: -12vw;
      
    }

    .mainpg__text h1{
        font-size: 1.5rem;
        margin-left: 11vw;
        margin-top: 48vh;
        width: 90%;
        line-height: 2.1rem;
      
    }
    .mainpg__text h2{
        font-size: .82rem;
        margin-top: 1.5rem;
        width: 90%;
        margin-left: 13vw;
        line-height: 1.1rem;
    }

    .btn11 {
       
        width: 250px;
        height: 60px;
       margin-left: 13vw;
       margin-top: 3rem;
    }
    .btn12 {
       
        font-size: .8rem;
        margin-left: 2rem;
        font-weight: 'bold';
    }
  }

  @media screen and (min-width: 751px) and (max-width: 830px) {

  
    .mainpg__container {
        height: 100vh;
        display:flex;
        scroll-behavior: smooth;
        flex-direction: column; 
    
    
    }

    .mainpg__vdo video {
    
        align-items: center;
        height: 56vh; 
        width: auto; 
        margin-left: 5vw;
        margin-top: 30vw;
      
    }

    .mainpg__text h1{
        
        text-align: center;
        font-size: 2.5rem;
        margin-left: 15vw;
        margin-top: 2vw;
        margin-bottom: 13vw;
        width: 100%;
        line-height: 3.1rem;
      
    }
    
    .mainpg__text h2{
        position: absolute;
        text-align: center;
        font-size: 1.rem;
        margin-top: 51vh;
        width: 90%;
        margin-left: 15vw;
        line-height: 1.1rem;
    }
    
    .btn11 {
       position: absolute;
       margin-left: 30vw;
       bottom: -69.7vh;
    }
    
    }

    @media screen and (min-width: 831px) and (max-width: 902px) {
         

        .mainpg__container {
            height: 100vh;
            display:flex;
            scroll-behavior: smooth;
            flex-direction: column; 
        
        
        }
    
        .mainpg__vdo video {
        
            align-items: center;
            height: 56vh; 
            width: auto; 
            margin-left: 5vw;
            margin-top: 30vw;
          
        }
    
        .mainpg__text h1{
            
            text-align: center;
            font-size: 3.5rem;
            margin-left: 10vw;
            margin-top: 2vw;
            margin-bottom: 13vw;
            width: 100%;
            line-height: 3.1rem;
          
        }
        
        .mainpg__text h2{
            position: absolute;
            text-align: center;
            font-size: 1.rem;
            margin-top: 51.5vh;
            width: 90%;
            margin-left: 15vw;
            line-height: 1.1rem;
        }
        
        .btn11 {
           position: absolute;
           margin-left: 40vh;
           bottom: -72.7vh;
        }


    }

    @media screen and (min-width: 903px) and (max-width: 1024px) {
         

        .mainpg__text h1{
        
            text-align: center;
            width: 100%;
            line-height: 3.1rem;
            margin-left: 13vw;
            margin-top: 1.5vw;
            margin-bottom: 13vw;
            font-size: 3.5rem;
          
        }
        
        .mainpg__vdo video {
    
            align-items: center;
            height: 50vh; 
            width: auto; 
            margin-left: 11vw;
            margin-top: 25vw;
          
        }
        .mainpg__text h2{
            position: absolute;
            text-align: center;
            font-size: 1.rem;
           
            width: 90%;
           
            line-height: 1.1rem;
            margin-top: 40vh;
        
            margin-left: 17vw;
          
        }
        

        .btn11 {
            bottom: -57vh;
            margin-left: 38vw;
           
         }
    }

