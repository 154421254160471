header {
    height:130vh;
    padding-top: 7rem;
    overflow:  hidden;
}

.header__container {
    text-align: center;
    height: 100;
    position: relative;
}

.header__container h5 {
    font-size: 1.0rem;
}

/*-------------CTA--------------*/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.4rem;
    justify-content: center;
}


.ShufflingSomfin {
    top: 17rem;
    display: flex;
    max-width: 70%;

}
.header__socials{
 content: "";
 width: 1px;
 height: 2rem;
 background: var(--color-primary)
}

/*-------------scroll----------*/
.scroll__down{
    position: absolute;
    right: -7.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;

}

.save {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 23rem;
    position: absolute;
    left: calc(30% - 25rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem;
    opacity: 0; 
    animation: fadein 1.5s ease forwards; 
}

@keyframes fadein {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
}


.save span {
    color: #4f297e; 
    font-size: 1.3rem; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: absolute;
    bottom: 0.1rem; 
    left: 50%; 
    transform: translateX(-50%); 
}



.grow {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 23rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem;
    opacity: 0; 
    animation: fadein 1.8s ease forwards; 
}

@keyframes fadein {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
}

.grow span {
    color: #4f297e; 
    font-size: 1.3rem; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: absolute;
    bottom: 0.1rem; 
    left: 50%; 
    transform: translateX(-50%); 
}



.secure {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 23rem;
    position: absolute;
    left: calc(70% - 1rem);
    margin-top: 4rem;
    margin-left: 3rem;
    margin-bottom: 5rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem;
    opacity: 0; 
    animation: fadein 2.1s ease forwards; 
}

@keyframes fadein {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
}

.secure span {
    color: #4f297e; 
    font-size: 1.3rem; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    position: absolute;
    bottom: 0.1rem; 
    left: 50%; 
    transform: translateX(-50%); 
}


/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 1024px) {
    .header{
        height: 68vh;
    }
}

/*-----------SOMFIN MEDIA QUERIES (small devices)---------*/

@media screen and (max-width: 600px) {
    .header{
        height: 100vh;
    }


    .header__socials,
    .scroll__down {
    display: none;
    }
}
