.cards {
    display: grid;
    height:120vh;
    gap: 2rem;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

}

.Card {
    background-color: white;
    padding: 30px;
    border-radius: 12px;       
    transition: height 0.3s ease-in-out;
    margin-top: 100px;
}

.Card .image-holder {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.Card:nth-child(1) .image-holder {
   background-image: url(../../../assets/sgssa/savecard/somfii.png);
   
}
.Card:nth-child(2) .image-holder {
    background-image: url(../../../assets/sgssa/savecard/SOM2.jpg);
}
.Card:nth-child(3) .image-holder {
    background-image: url(../../../assets/sgssa/savecard/SOM3.jpg);
}

.Card h2, .Card p {
    margin-bottom: 15px;
}

.Card p {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.1s;
}

.Card a {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.1s;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 15px;
    border-radius: 4px;
    background-color: red;
}

.Card:hover .image-holder {
    transform: scale(0.9);
    margin-top: -120px;
}

.card-content {
    height: 75px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.Card:hover .image-holder {
    transform: scale(0.9);
    margin-top: -120px;
}

.Card:hover .card-content {
    height: 340px;
    overflow: visible;
}

.Card:hover p, .Card:hover a {
    opacity: 1;
}


/*--------------------------------mediaquerry---------------------------*/
@media screen and (max-width: 600px) {
    .cards {
        grid-template-columns: 1fr;
        justify-content: center;
        margin-top: 10rem;
        width: 80%;
    }

}
