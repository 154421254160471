.section__head {
    display: grid;
    grid-template-columns: auto 1fr; 
    align-items: center;
    gap: 0.6rem;
    margin-top: 10rem;
    
}
.section__head h2 {
    font-size: 2rem;
    color: var(--color-bg-variant);
}

.section__head span {
    font-size: 2rem;
    color: var(--color-bg-variant)
}