.fourthpg_container{
    width: 100%;
    height: 100vh;
}


.part-2{
    display: flex;
    align-items: end;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/fourthpg/fourthbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.part-2 img{

  
  transition: all cubic-bezier(0.19, 1, 0.22, 1)1s;
  
}

#imgthree{
    position: relative;
    bottom: -50vh;
    z-index: 5;
    width: 45vh;

}

#imgtwo, #imgfour{
    position: relative;
    z-index: 4;
    bottom: -50vh;
   
}

#imgtwo{
    width: 40vh;
    left: 30%;
}

#imgfour{
    right: 30%;
    width: 40vh;
}

#imgone, #imgfive{
    position: relative;
    bottom: -50vh

}

#imgone{
    width: 40vh;
    left: 18%;

}

#imgfive{
    width: 40vh;
    right: 18%;
}


/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 1024px) {
    .part-2 img{
        height: 60vh;
        width: 40vh;
        
    }

    .part-2 img{

 
        transition: all cubic-bezier(0.22, 1.5, 0.52, 1)2s;
        
    }
    #imgthree{
        position: relative;
        bottom: -50vh;
        z-index: 5;
    }
    
    #imgtwo, #imgfour{
        position: relative;
        z-index: 4;
        bottom: -50vh;
       
    }
    
    #imgtwo{
        left: 25%;
    }
    
    #imgfour{
        right: 25%;
    }
    
    #imgone, #imgfive{
        position: relative;
        bottom: -60vh
    
    }
    
    #imgone{
        left: 50%;
    }
    
    #imgfive{
        right: 50%;
    }
}


/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 600px) {
    .part-2 img{
        height: 37vh;
        width: 19vh;
        
    }
    #imgthree{
        position: relative;
        bottom: -50vh;
        z-index: 5;
    }
    
    #imgtwo, #imgfour{
        position: relative;
        z-index: 4;
        bottom: -50vh;
       
    }
    
    #imgtwo{
        left: 25%;
    }
    
    #imgfour{
        right: 25%;
    }
    
    #imgone, #imgfive{
        position: relative;
        bottom: -60vh
    
    }
    
    #imgone{
        left: 50%;
    }
    
    #imgfive{
        right: 50%;
    }
}
