.secure__container{
   display: flex;
   height: 100vh;
   width: 100%; 
   background-color: white;

   justify-content: center;
   align-items: center;
   
}

.secure_p1 {
    position: absolute;
    display: flex;
    margin-top: 10vh;
    text-align: center;
  
}

.secure_p1 h1{
  
    margin-top: -15vw;
    font-weight: 900;
    font-size: 3.3rem;
}


.absolute {
    position: absolute;
    inset:0;
    justify-content: center;
    display: inline-flex;
    flex-direction: row;
    margin-top: 4rem;
}

.justify_center{
   justify-content: center;

}

.bg-shape1{
  

    width: 300px;
    height: 400px;
    border-radius: 9999px;
    position: relative;
    animation: one 5s infinite;

}

.bg-shape2{
  

    width: 500px;
    height: 600px;
    border-radius: 9999px;
    position: relative;
    animation: two 10s infinite;

}

@keyframes one {
    0%{left: 0px; top: 0px;}
    25%{left: -100px; top: 70px;}
    50%{left: 20px; top: 150px;}
    75%{left: 50px; top: 100px;}
    100%{left: 0px; top: 0px;}
}

@keyframes two {
    0%{left: 0px; top: 0px;}
    25%{left: 50px; top: 10px;}
    50%{left: 100px; top: 50px;}
    75%{left: 50px; top: 100px;}
    100%{left: 0px; top: 0px;}
}

.opacity-50{
    opacity: .78;

}

.bg-blur{
    filter: blur(400px);

}

.bg-primary{
    background-color: rgb(87, 17, 152);
}

.bg-teal{
    background-color:  rgb(140, 0, 215);
}

.bg-purple{
    background-color:rgb(235, 243, 91);
}


  
.button1 {
    font: inherit;
    padding: 0.75em 2em;
    background: hsl(271, 100%, 85%);
    border: 0.1em solid hsl(269, 61%, 24%);
    border-radius: 100vw;
    cursor: pointer;
    transition: background-color 250ms;
  
    position: absolute;
    isolation: isolate;
    overflow: hidden;
    margin-top: 6vw;
    font-size: 1.2rem;
    font-weight: 600;
    color:#4f297e;
  }
  
  .button1:hover,
  button:focus-visible {
    background: hsl(265, 46%, 87%);
  }
  
  .button1 > span {
    position: absolute;
    z-index: -1;
    width: 33.333%;
    height: 100%;
  
    background: transparent;
    opacity: 0.5;
  }
  
  .button1 > :first-child {
    left: 0;
    top: 0;
  }
  
  .button1 > :last-child {
    right: 0;
    top: 0;
  }
  
  .button1::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: hsl(273, 64%, 17%);
    /* background: hotpink; */
    width: 10%;
    aspect-ratio: 1;
    border-radius: 50%;
    inset: 0;
    margin: auto;
    opacity: 0;
  
    transition: transform 1000ms 200ms, opacity 200ms;
  }
  
  .button1:active::before {
    transform: scale(20);
    opacity: 1;
  
    transition: transform 1000ms, opacity 500ms;
  }
  
  .button1:has(:first-child:active)::before {
    margin-left: 0;
  }
  
  .button1:has(:last-child:active)::before {
    margin-right: 0;
  }
  
  .button1:has(:first-child:active)::before,
  .button1:has(:last-child:active)::before {
    transition: transform 500ms, opacity 250ms;
  }
  .btn32{
    position: absolute;
    width: max-content;
    display: inline-block;
    font-weight: bolder;
    color: var(--color-bg-variant);
    padding: 0.75rem 1.2rem;
    border-radius: 1.8rem;
    cursor: pointer;
    border: 4px solid var(--color-bg-variant);
    transition: var(--transition);
    margin-top: 16rem;
    font-size: 1.3rem;
    
  
  }
  
.btn32:hover {
    background: var(--color-bg-variant);
    color: var(--color-bg);
  }


.card__container {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 55vw;
    margin-left: -5vw;
    


}
  
.card__article {
    position: relative;
    overflow: hidden;
    margin-bottom: 10rem;
}
  
.card__img {
    width: 350px;
    border-radius: 1.5rem;
    margin-top: 7rem;
    margin-left: 5rem;
  
}
  
  .card__data {
    width: 280px;
    background-color: white;
    padding: 4rem 2rem;
    box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15);
    border-radius: 1rem;
    position: absolute;
    bottom: -13rem;
    left: 5rem;
    right: 0;
    margin-inline: auto;
    opacity: 0;
    transition: opacity 1s 1s;
  }
  
  .card__description {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: .25rem;
  }
  
  .card__title {
    font-size: var(--h2-font-size);
    font-weight: 500;
    color: var(--title-color);
    margin-bottom: .75rem;
  }
  
  .card__button {
    text-decoration: none;
    font-size: var(--small-font-size);
    font-weight: 500;
    color: var(--first-color);
  }
  
  .card__button:hover {
    text-decoration: underline;
  }
  
  /* Naming animations in hover */
  .card__article:hover .card__data {
    animation: show-data 1s forwards;
    opacity: 1;
    transition: opacity .3s;
  }
  
  .card__article:hover {
    animation: remove-overflow 2s forwards;
  }
  
  .card__article:not(:hover) {
    animation: show-overflow 2s forwards;
  }
  
  .card__article:not(:hover) .card__data {
    animation: remove-data 1s forwards;
  }
  
  /* Card animation */
  @keyframes show-data {
    50% {
      transform: translateY(-10rem);
    }
    100% {
      transform: translateY(-7rem);
    }
  }
  
  @keyframes remove-overflow {
    to {
      overflow: initial;
    }
  }
  
  @keyframes remove-data {
    0% {
      transform: translateY(-7rem);
    }
    50% {
      transform: translateY(-10rem);
    }
    100% {
      transform: translateY(.5rem);
    }
  }
  
@keyframes show-overflow {
    0% {
      overflow: initial;
      pointer-events: none;
    }
    50% {
      overflow: hidden;
    }
}


/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 1024px) {
  .secure__container{
    display: flex;
    height: 140vh;
    width: 100%;
    justify-content: center;
    align-items: start;
    
 }
  .card__container { 
    display: grid;
    grid-template-columns: 1fr 1fr ;
    margin-top: 40vh;
    row-gap: 1rem;
    margin-left: -7vh;

  }

  .card__data {
    bottom: -17rem;
     
  }

  .secure_p1 h1{
    width: 100%;
    margin-top: -1vw;
    font-weight: 900;
    font-size: 3.0rem;
  } 

  .absolute {
    position: absolute;
    width: 90%;
    height: 100%;
    margin-top: 10vh;   
  }
  .opacity-50{
    opacity: 0.38;

  }

  .button1 {
    margin-top: 22rem;
 }


}

/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 600px) {
  
  .secure__container{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: start;
    
 }
  .card__container { 
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 60vh;
    margin-left: -9vh;
  
  }

  .card__data {
    bottom: -17rem;
     
  }

  .secure_p1 h1{
  
    margin-top: -1vw;
    font-weight: 900;
    font-size: 2.3rem;
  } 

  .absolute {
    position: absolute;
    width: 50%;
    height: 100%;
    margin-top: 10vh;   
  }
  .opacity-50{
    opacity: 1.28;

  }

  .button1 {
     margin-top: 28rem;
  }
  .btn32 {
    font-size: 0.8rem;
    margin-top: 23rem;
 }
}