.groww_container {
    height: 120vh;
    position: relative; 
    padding-top: 10rem;
}
 
.groww_container::before {
    content: "";
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/pages/grow/growbg.png);
    background-size: cover;
    background-position: center;
    z-index: -1; 
}
 
.grow_head1 {
  text-align: center;
  position: relative;
}

.grow_head1 h2{
  font-size: 9rem;
  font-weight: 900;
  margin-top: -2.5rem;
  cursor: pointer;
}
.grow_head1 h2:hover{
  transform: scale(1.1);
}

.grow_head1 h5 {
  font-size: 2rem;
}

.grow_image1{
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}

.image1 {
  grid-column: 2/3;
  justify-self: end;
}