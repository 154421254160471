/* Glassmorphism background setup */
* {
  margin: 0; /* Remove default margin */
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.plaaneer-body {
  overflow: hidden;
}

.plaaneer-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f1f4f9, #b784e7);
  position: relative;
}

.plaaneer-color {
  position: absolute;
  filter: blur(150px);
}

.plaaneer-color:nth-child(1) {
  top: -350px;
  width: 600px;
  height: 600px;
  background: #ab35ff;
}

.plaaneer-color:nth-child(2) {
  bottom: 50px;
  left: 100px;
  width: 5px;
  height: 300px;
}

.plaaneer-color:nth-child(3) {
  bottom: 50px;
  left: 100px;
  width: 30px;
  height: 300px;
}

.plaaneer-box {
  position: relative;
}

.plaaneer-box .square {
  position: absolute;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  animation: animate 10s linear infinite;
  animation-delay: calc(-1s * var(--i));
}

@keyframes animate {
  0%, 100% {
      transform: translateY(-40px);
  }
  50% {
      transform: translateY(40px);
  }
}

.plaaneer-box .square:nth-child(1) {
  top: 40px;
  right: 30vw;
  width: 100px;
  height: 100px;
}

.plaaneer-box .square:nth-child(2) {
  top: 150px;
  left: 20vw;
  width: 120px;
  height: 120px;
  z-index: 2;
}

.plaaneer-box .square:nth-child(3) {
  bottom: 50px;
  right: 55vh;
  width: 80px;
  height: 80px;
  z-index: 2;
}

.plaaneer-box .square:nth-child(4) {
  bottom: -80px;
  left: 32vw;
  width: 50px;
  height: 50px;
}

.plaaneer-box .square:nth-child(5) {
  top: -80px;
  left: 30vw;
  width: 60px;
  height: 60px;
}

/* New squares for left-side animation */
.plaaneer-box .square:nth-child(6) {
  top: -50px;
  left: 60px;
  width: 100px;
  height: 100px;
  animation-delay: 1s; /* Delay to stagger animation */
}

.plaaneer-box .square:nth-child(7) {
  top: 150px;
  right: -100px;
  width: 120px;
  height: 120px;
  z-index: 2;
  animation-delay: 2s; /* Delay to stagger animation */
}

.plaaneer-box .square:nth-child(8) {
  bottom: 50px;
  left: -60px;
  width: 80px;
  height: 80px;
  z-index: 2;
  animation-delay: 3s; /* Delay to stagger animation */
}

.plaaneer-container {
  position: relative;
  width: 400px;
  min-height: 400px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  z-index: 1; /* Ensure it's above background colors */
}

.plaaneer-form {
  width: 100%;
}

.plaaneer-form h1 {
  color: #530e8a;
  margin-bottom: 20px;
}

.plaaneer-form-container h38 {
  color: #4f297e;
  font-size: 3rem;
  font-weight: 600;
  margin-left: 4.5rem;
}

.plaaneer-form-container h23 {
  color: #4f297e;
  margin-left: .5rem;
  font-weight: 200;
  font-size: 1.4rem;
}

.plaaneer-form input[type="text"],
.plaaneer-form input[type="email"] {
  width: calc(100% - 20px);
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 35px;
  background: rgba(254, 254, 254, 0.986);
  color: #590da0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.plaaneer-form button {
  padding: 10px 15px;
  background-color: #fff;
  color: #666;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.plaaneer-form button:hover {
  background-color: #e6e6e6;
}

.plaaneer-download-section {
  margin-top: 20px;
}

.plaaneer-download-button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.plaaneer-download-button:hover {
  background-color: #218838;
}

.plaaneer-error-message {
  color: red;
  font-size: 0.9em;
}

.plaaneer-success-message {
  color: green;
  font-size: 1em;
  margin-top: 10px;
}

/* Additional styling for checkboxes and labels */
.plaaneer-label {
  color: #57008a;
  display: block;
  margin: 10px 0;
  font-weight: 900;
}

.plaaneer-form input[type="checkbox"] {
  margin-right: 5px;
}

/* Responsive Styles */
/* Responsive Styles */
@media (max-height: 600px) {
  .plaaneer-dashboard {
    padding: 10px; /* Reduced padding for better fit */
    flex-direction: column; /* Stacked layout */
    height: 100vh; /* Use full viewport height */
    overflow-y: auto; /* Enable scrolling for overflow */
    justify-content: flex-start; /* Align items to the top */
  }

  .plaaneer-box {
    width: 90%; /* Slightly reduced width for better fit */
    max-width: 360px; /* Limit max width for small screens */
    margin: 0 auto; /* Center the box */
  }

  .plaaneer-container {
    width: 100%; /* Full width */
    max-height: 70vh; /* Limit height to keep it visible */
    overflow-y: auto; /* Enable scrolling within the container */
    padding: 10px; /* Adjust padding for snug fit */
    border-radius: 10px; /* Consistent styling */
  }

  /* Completely hide all squares on small screens */
  .plaaneer-box {
    display: flex; /* Ensure box still flexes */
    position: relative; /* Maintain positioning */
  }

  .plaaneer-box .square {
    display: none !important; /* Hide squares */
  }

  .plaaneer-form h1 {
    font-size: 1.3rem; /* Adjusted font size */
    text-align: center; /* Center alignment */
  }

  .plaaneer-form-container h38 {
    font-size: 1.5rem; /* Reduced font size */
    margin: 1rem; /* Reduced margin */
    text-align: center; /* Center alignment */
  }

  .plaaneer-form-container h23 {
    font-size: 0.9rem; /* Smaller font size */
    margin-left: 0; /* Remove left margin */
    text-align: center; /* Center alignment */
  }

  .plaaneer-form input[type="text"],
  .plaaneer-form input[type="email"] {
    padding: 8px; /* Consistent padding */
    font-size: 0.9rem; /* Adjust font size */
    margin: 5px 0; /* Reduced margin for inputs */
  }

  .plaaneer-form button {
    padding: 8px 12px; /* Adjust button padding */
    font-size: 0.9rem; /* Consistent button font size */
    width: 100%; /* Full width for buttons */
  }

  .plaaneer-download-button {
    padding: 8px 12px; /* Adjust button padding */
    width: 100%; /* Full width for download button */
  }
}
