.analyze__container {
    display: flex;
    height: 100vh;
}

.text_container{
    margin-top: 5rem;
}

.text_container h12{
    position: absolute;
    text-align: center;
    font-size: 5rem;
    width: 50%;
    margin-top: 5VW;
    margin-left: 3vw;
    font-weight: 600;
    color: rgb(74, 11, 132);

}
.text_container h2{
    position: absolute;
    text-align:start;
    font-size: 1.3rem;
    margin-top: 49vh;
    margin-left: 9vw;
    font-weight: 600;
    color: rgb(74, 11, 132);
    width: 50%;

}
#imgonee{
    width: 120vh;
    position: absolute;
    left:43vw;
    top: 5vw;

   
}

.button2 {
    font: inherit;
    padding: 0.75em 2em;
    background: hsl(0, 0%, 100%);
    border: 0.1em solid hsl(269, 61%, 24%);
    border-radius: 100vw;
    cursor: pointer;
    transition: background-color 250ms;
  
    position: absolute;
    isolation: isolate;
    overflow: hidden;
    margin-top: 40vw;
    margin-left: 28vw;
    font-size: 1.2rem;
    font-weight: 600;
    color:#4f297e;
  }
  
  .button2:hover,
  button:focus-visible {
    background: hsl(265, 46%, 87%);
  }
  
  .button2 > span {
    position: absolute;
    z-index: -1;
    width: 33.333%;
    height: 100%;
  
    background: transparent;
    opacity: 0.5;
  }
  
  .button2 > :first-child {
    left: 0;
    top: 0;
  }
  
  .button2 > :last-child {
    right: 0;
    top: 0;
  }
  
  .button2::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: hsl(273, 64%, 17%);
    /* background: hotpink; */
    width: 10%;
    aspect-ratio: 1;
    border-radius: 50%;
    inset: 0;
    margin: auto;
    opacity: 0;
  
    transition: transform 1000ms 200ms, opacity 200ms;
  }
  
  .button2:active::before {
    transform: scale(20);
    opacity: 1;
  
    transition: transform 1000ms, opacity 500ms;
  }
  
  .button2:has(:first-child:active)::before {
    margin-left: 0;
  }
  
  .button2:has(:last-child:active)::before {
    margin-right: 0;
  }
  
  .button2:has(:first-child:active)::before,
  .button2:has(:last-child:active)::before {
    transition: transform 500ms, opacity 250ms;
  }
  

.feat_container{
    margin-top: 100vh;
    height: 100vh;
    width: auto;
 

 
  
}

.text_feat{
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top:25rem;
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
}

.text_feat h8{
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 8rem;
    margin-left: -15rem;
    margin-bottom: 2rem;
}

.text_feat h8:nth-child(1){
    color: transparent;
    -webkit-text-stroke: 2px rgb(52, 0, 80);
}

.text_feat h7{
  font-weight: 700;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  position: absolute;
  margin-top: -15.3rem;
  font-size: 8rem;
  margin-left: 27rem;

}

.text_feat h8:nth-child(2){
    color:rgb(162, 65, 242);
    animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
    0%, 100%
    {
        clip-path: polygon(0% 43%, 18% 46%, 32% 51%, 46% 61%,
         60% 65%, 71% 61%, 83% 57%, 100% 52%, 
         99% 99%, 1% 99%);
    }

    50%
    {
        clip-path: polygon(0 59%, 18% 66%, 35% 65%, 
        50% 56%, 63% 45%, 75% 38%, 86% 33%,
         100% 34%, 99% 99%, 1% 99%);

    }
}

.note__feat{
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

  
  /*-----------SOMFIN MEDIA QUERIES (small devices)---------*/

@media screen and (max-width: 600px) {
  .text_container h12{
   font-size:8vw;
   text-align: end;
   width: 80%;
   margin-left: 1vw;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  }

  #imgonee{
    position: absolute;
    
    width: 100%;
    left: -5%;
    top: 30%;
    
    
   
  }

  .button2{
    align-self: flex-end;
    margin-left: 20%;
    margin-bottom: 40vw;
  }

  .text_container h2{
   text-align: center;
   font-size: 4vw;
   width: 100%;
   margin-left: 1vw;

}

.text_feat h8{
  position: absolute;
  margin-left: 58vw;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  margin-top: -23rem;
  
}

.text_feat h7 {
  position: absolute;
  width: 70%;
  font-size: 3rem;
  left:-7.0em;
  font-family: 'Poppins';
  top: 2.3vh;
  

}
.note__feat{

  text-align: center;
  width: 80%;
  margin-left: 10vw;
  margin-top: -11vh;
} 
 
}


/*-----------SOMFIN MEDIA QUERIES (small devices)---------*/

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .text_container h12{
   font-size:8vw;
   text-align: end;
   width: 80%;
   margin-left: 1vw;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  }

  #imgonee{
    position: absolute;
    
    width: 100%;
    left: 2%;
    top: 30%;
    
    
   
  }

  .button2{
    align-self: flex-end;
    margin-left: 35%;
    margin-bottom: 5vw;
  }

  .text_container h2{
   text-align: center;
   font-size: 3vw;
   width: 100%;
   margin-left: 1vw;
   margin-top: 95vw;

}

.text_feat h8{
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  margin-top: -3rem;
  
}

.text_feat h7 {
  position: absolute;
  width: 60%;
  font-size: 3rem;
  left:-15%;
  font-family: 'Poppins';
  top: 13.3vh;
  

}
.note__feat{

  text-align: center;
  width: 80%;
  margin-left: 11vw;
  margin-top: 5vh;
} 
 
}