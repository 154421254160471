#part-5{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0vw 0vw 0vw 10vw;
    height: 100vh;
    background-color: white;
}

.lft-part-5{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 50%;
    height: 100vh;
}

.scroll-txt{
    position: absolute;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 8vh;

}

.scroll-txt h1{
    font-size: 5rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 600;
    color: rgb(60, 0, 102);

}

.lft-part-5 p{
    width: 90%;
    color: rgb(62, 0, 124);
}

.rght-part-5{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100vh;
}

.phone{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 20vw;
    height: 70vh;
    border-radius: 50px;
    border: 0.6vw solid #350057;
    overflow: hidden;
}

.phone-img{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 400%;
    height: 100%;
    margin-left: -7%;
}

.phone img{
    width: 20vw;
}



#scroll-2, #scroll-3, #scroll-4{
    opacity: 0;
     /*transition: all cubic-bezier(0.19, 1, 0.22, 1)3s; */ 
}

/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 1024px) {
    #part-5{
        display: flex;
        flex-direction: row;
      
    }
    .scroll-txt h1{
        font-size: 4rem;
       
    }
    .phone{
        display: flex;
        align-items: baseline;
        justify-content: start;
        width: 33vw;
        height: 45vh;
        border-radius: 29px;
        border: 0.8vw solid #350057;
        overflow: hidden;
        
    }

    .phone-img{
        display: flex;
        align-items: center;
        justify-content: start;
        width: 400%;
        height: 100%;
        margin-left: 7%;
    }
    
    .phone img{
        width: 30vw;
    }

}

/*-----------SOMFIN MEDIA QUERIES (Medium devices)---------*/

@media screen and (max-width: 600px) {
    #part-5{
        display: flex;
        flex-direction: row;
      
    }
    .scroll-txt h1{
        font-size: 2rem;
       
    }
    .phone{
        display: flex;
        align-items: center;
        justify-content: start;
        width: 35vw;
        height: 30vh;
        border-radius: 10px;
        border: 0.8vw solid #350057;
        overflow: hidden;
        
    }

    
    
    
}