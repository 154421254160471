.service__container {
    display: grid;
    grid-template-columns: 31.33% 31.33% 31.33%;
    gap: 5%;

}

.service__text h2 {
    text-align: center;
    margin-bottom: 7rem;
    font-size: 2.5rem;
    
}

.service__text h5 {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 7rem;
    color: #672aa0;
    font-size: 1.2rem;
}

.Install span {
    color: #4f297e; 
    font-size: 1.9rem; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
    margin-left: 7rem ;
    
    
}

.Setup span {
    color: #4f297e; 
    font-size: 1.9rem; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 7rem;
    
}
.Enjoy span {
    color: #4f297e; 
    font-size: 1.9rem; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 7.8rem;
    
}

.btnnn {
    width: max-content;
    position:relative;
    left: 65vh;
    top: 5vh;
    height: 7vh;
    color: var(--color-primary);
    padding: 0.75rem 2.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    
  
}
  
.btnnn:hover {
    background: var(--color-white);
    color: var(--color-bg);
}

/* SOMFIN MEDIA QUERIES (Medium device)---------------*/

@media screen and (max-width: 1024px) {
    .Install span{
        align-items: center;
        font-size: 1.5rem;
        margin-left: 3.7rem;
     }
     .Setup span{
        align-items: center;
        font-size: 1.5rem;
        margin-left: 3.6rem;
     }
    .Enjoy span{
       align-items: center;
       font-size: 1.5rem;
       margin-left: 3.9rem;
    }
    .btnnn{
        position: relative;
        left: 33vh;
    }



}


/*-----------SOMFIN MEDIA QUERIES (small devices)---------*/

@media screen and (max-width: 600px) {
    
    .Install span{
        align-items: center;
        font-size: 1.5rem;
        margin-left: 1rem;
     }
     .Setup span{
        align-items: center;
        font-size: 1.5rem;
        margin-left: 1rem;
     }
    .Enjoy span{
       align-items: center;
       font-size: 1.5rem;
       margin-left: 1.5rem;
    }
    .btnnn {
        position: relative;
        left: 23vh;
    }

}
@media screen and (max-width: 500px) {

    .btnnn {
        position: relative;
        left: 13vh;
    }

} 