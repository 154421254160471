  .about__container {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 12%;
    
}

.about__us {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2cqi;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
    margin-left: -2rem;
    margin-top: 1rem;
}

.about__us-image {
    width: 220%;
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(0deg);
    transition: var(--transition);
}

.about__us-image:hover {
    transform: rotate(10deg);
}

.about__me-image:hover {
   transform: rotate(10deg)
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-left: 0rem;
    
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    align-content: baseline;
    transition: var(--transition);
    height: 90%;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: #C8A2F8;
    font-size: 2.9rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    color:#E4D6F5;
    font-size: 1.25rem;
}
.about__card h5:hover {
    color: var(--color-bg-variant);
}

 
.about__card small {
    font-size: 0.7rem;
color: whitesmoke;
}
.about__card small:hover {
    font-size: 0.8rem;
color: #6A329F;
}

.about__content p {
    position: relative;
    margin: 2rem -10rem 3rem;
    color: #4f297e;
    align-items: center;
    width: 300%;
}


.btnn {
    width: fit-content;
    display: inline-block;
    height: 7vh;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    margin: 14rem 0 10rem 0;
  
}
  
.btnn:hover {
    background: var(--color-white);
    color: var(--color-bg);
}




@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
   
    .about__us {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    
    .about__content p {
        position: relative;
        margin: 2rem -15rem 3rem;
        color: #4f297e;
        align-items: center;
        width: 150%;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        

    }

    .about__card {
        display: grid;
        height: 25vh;
        width:90%;
        align-items: center;
    }
    .about__icon {
        margin-left: 40%;
       }
}

/*-----------SOMFIN MEDIA QUERIES (small devices)---------*/

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
        max-width: 100%;
    }

    .about__content p {
        position: relative;
        margin: 2rem -13rem 3rem;
        color: #4f297e;
        align-items: center;
        width: 190%;
    }
   .about__icon {
    margin-left: 40%;
   }
} 