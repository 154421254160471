.feat_container{
    height: 60vh;
    width: auto;
 

 
  
}

.text_feat{
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top:25rem;
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
}

.text_feat h8{
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 8rem;
    margin-left: -15rem;
    margin-bottom: 2rem;
}

.text_feat h8:nth-child(1){
    color: transparent;
    -webkit-text-stroke: 2px rgb(52, 0, 80);
}

.text_feat h7{
  font-weight: 700;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  position: absolute;
  margin-top: -15.3rem;
  font-size: 8rem;
  margin-left: 27rem;

}

.text_feat h8:nth-child(2){
    color:rgb(162, 65, 242);
    animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
    0%, 100%
    {
        clip-path: polygon(0% 43%, 18% 46%, 32% 51%, 46% 61%,
         60% 65%, 71% 61%, 83% 57%, 100% 52%, 
         99% 99%, 1% 99%);
    }

    50%
    {
        clip-path: polygon(0 59%, 18% 66%, 35% 65%, 
        50% 56%, 63% 45%, 75% 38%, 86% 33%,
         100% 34%, 99% 99%, 1% 99%);

    }
}

.note__feat{
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

  
  