.nbar {
    width: 100vw;
    height: 4rem;
    background:transparent;
    backdrop-filter: blur(20px);
    border-bottom: 1px solid var(--color-primary);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

.ncontainer {
    display: flex;
    place-items: center;
    justify-content: space-between;
    position: relative;
    right: -20vw;
}

.nlogo{
    font-size: 2.2rem;
    font-weight: 900;
    color: #4f297e;
    position: inherit;
    left: 10vw
}
.somfin-logo{
    position: absolute;
    width:6vh;
    top:1vh;
    left: -7vh;
}
.nItems{
    display: flex;
    align-items: center;
    gap: 3.5rem;
    font-size: 1.7rem;
    font-weight: 500;
}

.nbas {
    color:#70499f;
}

.nbtns { 
    display: flex;
    align-items: center;
    gap: 2.5rem;

}

.navbuton{
    cursor: pointer;
    font-size: 1.8rem;
    color:#4f297e;
    background: transparent;
    position: relative;
    right: -13vw;
} 

.navbuton {
    display: none;
}

/*----------MEDIA QUERY(small screen)-----------*/
@media screen and (max-width: 1240px) {

    .nItems {
        flex-direction:column;
        gap:0;
        position: absolute;
        top: 3rem;
        right: -45vw;
        background: #e2dfe4;
        font-size: 1.1rem;
        
    }
.nItems li {
    box-shadow: -2rem 1rem 1.9rem rgba(0, 0, 0, 0.2);
    width: 100%;
}


.nItems a { 
    padding: 1.1rem 2.5rem;
    display:block;
    background: var(--color-primary); 


}
.nItems a:hover {
    background: #4f297e;
}

.navbuton{
    display: inline-block;
    position: absolute;
    right: -43vw;
}
.ncontainer{
    position: relative;
    right:0;
}

.somfin-logo{
    position: fixed;
    width:5.5%;
    top:1rem;
    left:3vw;
}

    
}

@media screen and (max-width: 600px){
    .somfin-logo{
        position: fixed;
        width:7.5%;
        top:1rem;
        left:2vw;
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .somfin-logo{
        position: fixed;
        width:4.5%;
        top:0.6rem;
        left:3vw;
    }
}