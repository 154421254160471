@font-face {
  font-family: 'Founder';
  src: url('../../../assets/fonts/FoundersGroteskXCond-Bold.otf') format('truetype');
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
}


.main-p{
  width: 100%;
  height: 100vh;

 
}

#main{
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: aquamarine;
  overflow: hidden;
}

#top{

  position: absolute;
  top: 0%;
  width: 100%;
  height: 50vh;
  background-color: rgb(255, 255, 255);
  z-index: 9;
  overflow: hidden;
}

#center{
  position: relative;
  width: 100%;
  height: 100vh;
  transform-origin: center;
  background-color: rgb(46, 0, 71);
  transition: all cubic-bezier(0.19, 1, 0.22, 1)1s;
  overflow: hidden;
}

#bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

#main h1{
  font-family: Founder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

#top h1{
  position: absolute;
  text-align: center;
  margin-top: 6vw !important;
  font-size: 17vw;
  
}

#bottom h1{
 
  font-size: 17vw;
  width: 100%;

  text-align: center;
  margin-top: 11vw;
}

.content{
  margin-top: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  gap: 4vh;
}

.content h4{
  font-size: 4vw;
  font-family: Founder;
  color:#deabff
}

.content h3{
  width: 55%;
  font-size: 3vw;
  font-family: CardinalR;
  text-align: center;
  font-weight: 400;
  color:white;

}

.content .btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18%;
  height: 4vw;
  border-radius: 70px;
  background-color: #fff;
  color: #411d75;
  font-family: Founder;
  font-size: 2vw;
}

.content h2{
  font-size: 20vw;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}





/*-----------SOMFIN MEDIA QUERIES (Small devices)---------*/

@media screen and (min-width: 601px)and(max-width: 1028px) {
  #top h1{

    text-align: center;
    margin-top: -20vh !important;
    
  }
  
  #bottom h1{
    font-size: 17vw;
    width: 100%;
 
    text-align: center;
    margin-bottom: -19vw;
  }
}  

@media screen and (max-width: 600px) {
  #top h1{
   
    margin-top: 20vh !important;
    
  }

  #bottom h1{
    margin-top: -12vh !important;
   
  }
  
  
}